import React from "react"
import theme from "../../styles/theme"

const IconEmail = ({ size, title }) => (
  <svg
    className="raise"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <title>{title}</title>
    <path
      fill={theme.colors.offwhite}
      d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
    />
  </svg>
)

const email = ({ size, title }) => IconEmail({ size, title })

export default email
