const theme = {
  colors: {
    darkBlue: "#24305E",
    lightBlue: "#A8D0E6",
    darkPink: "#F64C72",
    lightPink: "#F172A1",
    offwhite: "#DCE7FF",
    darkGray: "#333F58",
    lightGray: "#d3d3d3",
    lightGreen: "#71d99a",
    darkGreen: "#116466",
  },
}

export default theme
